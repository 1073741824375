import { Moment } from "moment";
import { Account } from "./account.interface";
import { Address } from "./address.interface";
import { Codes } from "./codes.interface";
import { CommunicationPreference } from "./communications.interface";
import { Contact } from "./contact.interface";
import { Entity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { Name } from "./name.interface";
import { Reference } from "./reference.interface";
import { IMonitoringOrganizationLink } from "./sharedInterfaces";

export enum USER_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  NEW = 2,
}

export enum DISABLED_PATIENT_REASON {
  DEATH = "death",
  HOSPITALIZATION = "hospitalization",
  PERSONALPROBLEM = "personalProblem",
  OTHER = "other",
}

export interface Patient extends Entity {
  resourceType: string;
  active: boolean;
  gender: string;
  identifier: Identifier[];
  telecom: Identifier[];
  name: Name[];
  maritalStatus: Codes;
  deceasedBoolean: boolean;
  address: Address[];
  communication: CommunicationPreference[];
  contact: Contact[];
  careProvider: Reference[];
  managingOrganization: Reference;
  monitoringOrganizations?: IMonitoringOrganizationLink[];
  healthcareservice: Reference[];
  birthDate: string;
  lastActivity: string;
  noSms?: boolean;
  disabledReason?: string;
  pseudoname?: string;
  // FR only :
  birthplace?: Reference;
  identity?: Identity[];
}

export interface PatientUser {
  patient: Patient;
  user: Account;
}

export interface PatientFilter {
  name?: string;
  firstname?: string;
  service?: string;
  withInactive?: boolean;
  services?: string; // table of string parsed with JSON
}
export interface AddPatientData {
  name: string;
  firstname: string;
  mail: string;
  phone: string;
  birthdate: string;
  gender: string;
  language: "fr" | "en" | "nl";
  careProvider?: Reference;
  nurse?: Reference;
  pharmacist: Reference;
  secretary?: Reference;
  internalIdentifier?: string;
  insurance?: string;
  insuranceRef?: Reference;
  insuranceIdentifier?: string;
  testUser: boolean;
  noSms: boolean;
  SSIN?: string;
  healthcareService?: Reference;
  monitoringOrganizations?: IMonitoringOrganizationLink[];
  organization?: Reference;
  customService?: Reference;
  customOrganization?: Reference;
  pseudoname?: string;

  // Fields for FR inscription
  firstnames?: string[];
  useName?: string;
  useFirstname?: string;
  birthplace?: Reference;
  identity?: Identity[];
  //NB:  Social security field is handle in insuranceIdentifier
  ins?: string;
  oid?: string;
  historicINS?: {
    ins: string;
    oid: string;
    begin: string;
    end: string;
  }[];
}
export interface AlreadyExistingPatient {
  birthDate: Moment;
  firstname: string;
  name: string;
  mail: string;
  phone: string;
  internalId: string;
  SSIN?: string;
  healthcareService: Reference[];
}

export interface IPatientInfo {
  caremateIdentifier: string;
  fullname: string;
  birthDate: string;
  ssin: string;
  phone: string;
  email: string;
}

export interface IPatientsListInfo {
  caremateIdentifier: string;
  status: USER_STATUS;
  name: string;
  firstname: string;
  email: string;
  phone: string;
  birthDate: string;
  gender: string;
  careProvider: Reference[];
  managingOrganization: Reference;
  monitoringOrganizations?: IMonitoringOrganizationLink[];
  healthcareservice: Reference[];
  careplanDescriptions: string[];
  lastActivity: string;
  insurance: Reference;
  registrationDate: string;
  pseudoname: string;
  useName?: string;
  useFirstname?: string;
}

export interface Identity {
  status: IDENTITY_STATUS;
  verification: IDENTITY_VERIFICATION;
  method: IDENTITY_VERIFICATION_METHOD;
  modificationType: MODIFICATION_TYPE;
  period: {
    start: string;
    end: string;
  };
  responsible: Identifier[]; // Identifier du practicien qui a crée / modifié cette identité (tableau car on peut mettre caremateId + autre identifier (inami, etc))
}
export enum IDENTITY_STATUS {
  temporary,
  recovered,
  validated,
  qualified,
}

export enum IDENTITY_VERIFICATION {
  suspicious,
  fictive,
  verified,
}

export enum IDENTITY_VERIFICATION_METHOD {
  passport,
  idCard,
  birthCertificate,
}

export enum MODIFICATION_TYPE {
  name,
  firstnames,
  birthDate,
  birthplace,
  gender,
  oid,
  ins,
  insiReturn,
  verification,
  method,
}
