import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { EntityDrug } from "src/app/models/entity.interface";
import { KeyValue } from "src/app/models/keyValues.model";
import { IStepwise } from "src/app/models/stepwiseDrug.interface";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-create-stepwise-drug",
  templateUrl: "./create-stepwise.component.html",
  styleUrls: ["./create-stepwise.component.scss"],
})
export class CreateStepwiseComponent implements OnInit, OnDestroy {
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  public timingCodes = ["rise", "morning", "noon", "evening", "bedtime"];
  public quantities: KeyValue[] = FormsData.QUANTITY;
  public timingSelected = 0;
  public stepwise: IStepwise;
  public form: UntypedFormGroup;
  public filteredQuantities: string[] = [];
  public availableQuantities: Map<string, Observable<string[]>[]> = new Map<string, Observable<string[]>[]>();
  public get timingsForm(): UntypedFormArray {
    return this.form.get("timings") as UntypedFormArray;
  }

  public get drugsForm(): UntypedFormArray {
    return this.timingsForm.at(this.timingSelected).get("drugs") as UntypedFormArray;
  }

  constructor(
    private dialogRef: MatDialogRef<CreateStepwiseComponent>,
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      mode: FORMS_MODE;
      startDay: number;
      nbDays: number;
      stepwise: IStepwise;
      quantitiesMustBeNumber?: boolean;
    }
  ) {
    this.form = this.fb.group({
      timings: this.fb.array([]),
    });
    this.timingCodes.forEach((c) => {
      this.availableQuantities.set(c, []);
      this.timingsForm.push(
        this.fb.group({
          drugs: this.fb.array([]),
        })
      );
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initForm(fullInit = false) {
    if (this.data.mode === FORMS_MODE.CREATE || fullInit) {
      this.stepwise = {
        startDay: this.data.startDay,
        days: [
          {
            moment: [],
          },
        ],
      };
    } else {
      this.stepwise = this.data.stepwise;
      this.stepwise.days[0].moment.forEach((t) => {
        const momentIndex = this.timingCodes.findIndex((timing) => timing === t.timingCode);
        if (momentIndex !== -1) {
          t.drugs.forEach((d) => {
            const qForm = this.createQuantityFormControl(d.quantity, t.timingCode);
            (this.timingsForm.at(momentIndex).get("drugs") as UntypedFormArray).push(
              this.fb.group({
                drugName: [d.drugInfo ? d.drugInfo : d.name],
                drugQuantity: qForm,
              })
            );
          });
        }
      });
    }
  }

  private createQuantityFormControl(quantityValue: string, timingCode: string): FormControl {
    const quantityValidator = this.data.quantitiesMustBeNumber ? [Validators.pattern(/^[0-9]*(\s\+\s)?([1-3]\/[2-4])?$/)] : undefined;
    const v = EntityDrug.computeQuantityDisplay(quantityValue);
    const fc = new FormControl(null, quantityValidator);
    // This will create some sort of autocomplete for the quantities
    // Each quantity input has its autocomplete filter:
    const obs: Observable<string[]> = fc.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      map((r) => {
        return FormsData.filterQuantities(r, fc.valid, this.data.quantitiesMustBeNumber);
      })
    );
    this.availableQuantities.get(timingCode).push(obs);
    setTimeout(() => {
      fc.setValue(v);
    }, 0);
    return fc;
  }

  public onAddDrug(): void {
    this.drugsForm.push(
      this.fb.group({
        drugName: [""],
        drugQuantity: this.createQuantityFormControl(null, this.timingCodes[this.timingSelected]),
      })
    );
  }

  public validate(): void {
    this.initForm(true);
    this.timingsForm.value.forEach((t, i) => {
      const drugsForm = t.drugs;
      const drugs = [];
      drugsForm.forEach((d) => {
        const drugInfo = d?.drugName as IDrugInfo;
        const name = drugInfo?.[this.sessionService.userLang]?.fullName;
        if (name?.length > 0) {
          drugs.push({
            name: name,
            quantity: EntityDrug.computeQuantityValue(d?.drugQuantity, this.data.quantitiesMustBeNumber),
            drugInfo: drugInfo,
          });
        } else if (d?.drugName) {
          drugs.push({
            name: d.drugName,
            quantity: EntityDrug.computeQuantityValue(d?.drugQuantity, this.data.quantitiesMustBeNumber),
          });
        }
      });
      if (drugs.length > 0) {
        this.stepwise.days[0].moment.push({
          timingCode: this.timingCodes[i],
          drugs: drugs,
        });
      }
    });
    for (let i = 1; i < this.data.nbDays; i++) {
      this.stepwise.days.push(this.stepwise.days[0]);
    }
    if (this.stepwise?.days[0]?.moment?.length) {
      this.dialogRef.close(this.stepwise);
    }
  }
}
