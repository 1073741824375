import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddServiceComponent } from "src/app/components/forms/add-service/add-service.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { OrganisationType, Organization } from "src/app/models/organization.model";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { AddOrganizationComponent } from "../forms/add-organization/add-organization.component";
import { UserListDialogComponentComponent } from "../user-list-dialog-component/user-list-dialog-component.component";
import { OrganizationDataSource } from "./organization-datasource";

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @Input() set setOrganization(org: Organization) {
    if (org) {
      this.isInsurance = org.organizationType === OrganisationType.INS || org.organizationType === OrganisationType.OLDINS;
      this.organization = org;
    }
  }
  @Input() set setExpand(exp: boolean) {
    if (exp) {
      this.onExpand();
    }
  }

  public organization: Organization;
  public services: Healthcareservice[] = [];

  public displayedColumns: string[] = ["name", "reference", "action"];
  public dataSource: OrganizationDataSource;
  public isExpand = false;
  public isInsurance = false;
  public isAdmin = false;

  /**
   * Filter
   */
  public globalSearchValue = "";
  public loading = false;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private serviceList$: Subscription;

  constructor(
    private healthcareserviceService: HealthcareserviceService,
    private sessionService: SessionService,
    private dialog: MatDialog
  ) {
    this.sessionService.refreshServicesList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData(this.organization);
    });
    this.isAdmin = this.sessionService.isAdmin();
  }

  ngOnInit(): void {
    this.dataSource = new OrganizationDataSource();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData(org: Organization) {
    if (!this.isInsurance) {
      if (this.serviceList$) {
        this.serviceList$.unsubscribe();
      }
      this.serviceList$ = this.healthcareserviceService
        .list(org.identifier[0].value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((services) => {
          this.services = services;
          this.dataSource.loadData(this.services);
          this.dataSource.sort = this.sort;
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  public onExpand(): void {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      this.initData(this.organization);
    }
  }

  public onEditOrganization(): void {
    // Modify the organization
    const dialogRef = this.dialog.open(AddOrganizationComponent, {
      disableClose: true,
      data: { organization: this.organization, mode: FORMS_MODE.UPDATE },
    });
    dialogRef.afterClosed().subscribe((data: { organization: Organization; mode: FORMS_MODE }) => {
      this.organization = data.organization;
      this.initData(this.organization);
    });
  }

  public onCreateService(): void {
    // Create a new service
    this.dialog.open(AddServiceComponent, {
      disableClose: true,
      data: {
        service: null,
        orgRef: this.organization?.identifier[0],
        mode: FORMS_MODE.CREATE,
      },
    });
  }

  public onEditService(service: Healthcareservice): void {
    // Edit the service
    this.dialog.open(AddServiceComponent, {
      disableClose: true,
      data: {
        service,
        orgRef: this.organization?.identifier[0],
        mode: FORMS_MODE.UPDATE,
      },
    });
  }

  public onDisplayService(service: Healthcareservice): void {
    this.dialog.open(UserListDialogComponentComponent, {
      disableClose: true,
      width: "80vw",
      data: { service, organization: this.organization },
      panelClass: "dialog-container-scroll",
    });
  }
}
